@import "./_variables.scss";

@font-face {
    font-family: 'WhippinPicadilly';
    src: url("../font/whippin-picadilly/WhippinPicadilly.eot");
    src: local('WhippinPicadilly'),
         url("../font/whippin-picadilly/WhippinPicadilly.ttf") format('truetype'),
         url("../font/whippin-picadilly/WhippinPicadilly.eot?#iefix") format('embedded-opentype'),
         url("../font/whippin-picadilly/WhippinPicadilly.woff2") format('woff2'),
         url("../font/whippin-picadilly/WhippinPicadilly.woff") format('woff'),
         url("../font/whippin-picadilly/WhippinPicadilly.svg#WhippinPicadilly") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

html, body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

body {
    font-family: 'WhippinPicadilly', Arial, Helvetica, sans-serif;
    font-size: 20px;
    background: $background-color;
    text-align: center;
}

h1,
h2,
h3 {
    font-weight: 100;
    padding-bottom: 0;
    margin-bottom: 0;
}

p {
    font-family: 'Roboto Condensed', sans-serif;
}

img.looped-gif {
    width: 100%;
    padding: 20px 0 20px 0;

    &.bag {
        max-width: 600px;
    }
}

.container {
    width: 100%;
}

#welcome,
#postcards,
#magnets,
#bags,
#world,
#shops {
    padding-top: 100px;
}

#welcome {
    .content {
        text-align: justify;
        overflow-wrap: break-word;
    }
}

.content {
    width: 1240px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.flex {
    display: flex;

    &.row {
        flex-flow: row;
    }

    &.column {
        flex-flow: column;
    }

    &.centered {
        align-items: center;
        justify-content: center;
    }
}

.arrow-down {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/arrow-down.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    
}

.arrow-up {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/arrow-up.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-color;
    transition-duration: 0.5s;
    height: calc(100vh);
    cursor: pointer;

    img {
        max-width: calc(75vw);
        max-height: calc(75vh);
    }

    &.hidden {
        display: none;
    }
}

#header-container {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: row;
        width: 100vw;
        height: 100px;
        background: $background-color;
        z-index: $top;
    
        &.hidden {
            display: none;
        }
    
        h1 {
            font-size: 36px;
            cursor: pointer;
            margin: 0;
        }
    
        .minilogo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
            cursor: pointer;
    
            img {
                width: 75px;
                height: 75px;
            }
        }
        
        .languages {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-end;
            width: 10%;
            
            .dot {
                padding: 0 5px
            }
        }
    
        .menu {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            width: 75%;
            
            .dot {
                padding: 0 20px
            }
        }   
    }
    
    .header-mobile {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        width: 100vw;
        min-height: 100px;
        background: $background-color;
        z-index: $top;
    
        &.hidden {
            display: none;
        }
    
        h1 {
            margin: 0;
        }

        .line {
            display: flex;
            flex-flow: row;
            align-items: center;
            height: 75px;
            cursor: pointer;
    
            &.main {
                height: 100px;
            }
    
            &.menu {
                display: none !important;
            }
    
            .middle { 
                width: 80%
            }
        
            .left, 
            .right {
                display: flex;
                flex-flow: row;
                width: 20vw;
            }
        
            .left {
                justify-content: flex-start;
            }
        
            .right {
                justify-content: flex-end;
            }
        
            .minilogo {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 25px;
    
                img {
                    width: 75px;
                    height: 75px;
                }
            }
        
            .hamburger {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 25px;
                width: 75px;
                height: 100px;

                &:hover {
                    cursor: pointer;
                }
        
                img {
                    width: 75px;
                    height: 75px;

                    &.open {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
    
            .languages {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: center;
                
                .dot {
                    padding: 0 5px
                }
            }
        }
    
        &.open {
            height: 100%;
    
            .line.menu {
                display: flex !important;
                justify-content: center;
            }
        }
    }

    &.hidden {
        .header {
            display: none !important;
        }
    
        .header-mobile {
            display: none !important;
        }
    }
}

.card {
    position: relative;
    width: 300px; 
    height: 100%;
    perspective: 1000px;

    &.postcard,
    &.magnet {
        height: 300px;
    }

    &.bag {
        height: 585px;
    }

    .back { 
        transform: rotateY(180deg); 
    }

    .front, .back {
        position: absolute;
        width: 100%; height: 100%;
        transition: transform 1s;
        backface-visibility:hidden;
    }

    &.hovered .front{ transform: rotateY(180deg); }
    &.hovered .back { transform: rotateY(360deg); }
}

.gallery {
    margin: 0 auto;

    .item {
        width: 300px;
        margin-bottom: 10px;
    }

    .thumb {
        width: 300px;
    }

    .__react_modal_image__modal_container {
        z-index: $most-top;
    }
}

.shop-list-container {
    font-family: 'Roboto Condensed', sans-serif;
    display: flex;
    flex-flow: row;
    font-size: 20px;
    padding-top: 20px;

    .left {
        width: calc(100% - 300px);
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1 0 auto;
        
        img {
            height: 20px;
            padding-right: 10px;
        }

        a {
            text-decoration: none;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 300px;

        img {
            max-width: 300px;
        }
    }
}

#social {
    .content {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .social-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 50%;
        min-width: 200px;
    
        img {
            max-height: 50px;
            max-width: 50px;
            padding: 0 15%;
        }

        .left {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    
        .middle {
            width: 34%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .right {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.cookie-consent-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

#cookie-consent-popup {
    font-family: 'Roboto Condensed', sans-serif;
    position: fixed;
    bottom: 20px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    padding: 0;
    background: #AEAEAE;
    opacity: .80;
    color: #000000;
    border: none;
    border-radius: 10px;
    box-shadow: 10px 8px 16px rgba(0, 0, 0, 0.25);
    z-index: 999999;
    width: 90%;    
}

#cookie-consent-popup .box.left {
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
    width: 75%;
}

#cookie-consent-popup .box.right {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    width: 25%;
}

#cookie-consent-popup .box.right .button {
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 3px #666666;
    -moz-box-shadow: 0px 1px 3px #666666;
    box-shadow: 0px 1px 3px #666666;
    font-family: Arial;
    color: #000000;
    font-size: 16px;
    background: #FFFFFF;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    cursor: pointer;
      
    &:hover {
        background: #8E8E8E;
        box-shadow: 0px 1px 3px #666666;
        text-decoration: none;
    }
}

@media (max-width: 1240px) {
    body {
        font-size: 20px;
    }

    .header-mobile {
        display: flex !important;
    }

    .header .menu, 
    .content {
        width: 930px;
    }

    .header-mobile {
        display: none !important;
    }
}

@media (max-width: 930px) {
    body {
        font-size: 20px;
    }

    .header {
        display: none !important;
    }
    
    .header-mobile {
        display: flex !important;
    }

    .content {
        width: 620px;
    }

    .arrow-down {
        height: 35px;
        width: 35px;
    }

    .arrow-up {
        height: 35px;
        width: 35px;
    }

    .shop-list-container {
        flex-flow: column;
    
        .left {
            width: 100%;
        }
    
        .right {
            justify-content: center;
            margin-top: 20px;
            width: 100%;
        }
    }
}

@media (max-width: 620px) {
    body {
        font-size: 16px;
    }

    .header {
        display: none !important;
    }

    .header-mobile {
        display: flex !important;
    }

    .content {
        width: 310px;
    }

    .arrow-down {
        height: 35px;
        width: 35px;
    }

    .arrow-up {
        height: 35px;
        width: 35px;
    }

    .shop-list-container {
        flex-flow: column;
        font-size: 16px;
    
        .left {
            width: 100%;

            img {
                height: 16px;
            }
        }
    
        .right {
            width: 300px;
        }
    }
}